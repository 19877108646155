<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('subject')">
                        <ValidationProvider name="subject" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.subject" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation"  rules="required" v-slot="{ valid, errors }">
                            <ckeditor v-model="formData.explanation"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('subject')">
                        <ValidationProvider name="subject_en" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1" prepend="EN">
                                <b-form-input v-model="formData.subject_en" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation_en"  rules="required" v-slot="{ valid, errors }">
                            <ckeditor v-model="formData.explanation_en"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code" :multiple="true"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="department_code" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="formData.department_code" :multiple="true"
                                :faculty_code="formData.faculty_code" :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="program_code" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code" :faculty_code="formData.faculty_code"
                                :department_code="formData.department_code" :multiple="true" :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="program_levels" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox v-model="formData.program_levels" :multiple="true"
                                                 :validate-error="errors[0]" code="program_levels"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('roles')">
                            <role-selectbox :multiple="true" v-model="formData.roles" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="formData.status" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="formData.start_date" :is-time="true" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="formData.end_date" :is-time="true" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="file" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file v-model="formData.file" :placeholder="$t('select_file')"
                                             ref="fileInput"></b-form-file>
                                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{
                                        $t('browse') }}</b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="link" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('link')">
                            <b-form-input v-model="formData.link"></b-form-input>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="show_on_login" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('show_on_login')">
                            <b-form-checkbox v-model="formData.show_on_login"></b-form-checkbox>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" md="6">
                    <ValidationProvider name="student_program_ids" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('student_numbers')">
                            <lined-textarea v-model="formData.student_program_ids"
                                            :validate-error="errors[0]"
                                            :styles="{ height: '15em', resize: 'both' }"
                            >
                            </lined-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

// Services
import AnnouncementsService from '@/services/AnnouncementsService';

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import RoleSelectbox from "@/components/interactive-fields/RoleSelectbox";
import SelectDate from "@/components/interactive-fields/SelectDate";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"



export default {
    components: {
        LinedTextarea,
        SelectDate,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        RoleSelectbox,
        MultiSelectbox,
        StatusSelectbox,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formLoading: false,
            formData: {},

        }
    },
    methods: {

        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
           if (isValid){
               const formData = new FormData();
               if (Array.isArray(this.formData.program_code)){
                   this.formData.program_code.forEach((itm, index) => {
                       if(itm && itm!='null'){
                           formData.append("program_code[" + index + "]", itm);
                       }
                   })
               }
               if (Array.isArray(this.formData.faculty_code)) {
                   this.formData.faculty_code.forEach((itm, index) => {
                       if (itm && itm != 'null') {
                           formData.append("faculty_code[" + index + "]", itm);
                       }
                   })
               }

               if (Array.isArray(this.formData.department_code)) {
                  this.formData.department_code.forEach((itm, index) => {
                       if (itm && itm != 'null') {
                           formData.append("department_code[" + index + "]", itm);
                       }
                   })
               }
               if (Array.isArray(this.formData.program_level)) {
                   this.formData.program_level.forEach((itm, index) => {
                       if (itm && itm != 'null') {
                           formData.append("program_level[" + index + "]", itm);
                       }
                   })
               }

               if (Array.isArray(this.formData.roles)) {
                  this.formData.roles.forEach((itm, index) => {
                       if (itm && itm != 'null') {
                           formData.append("roles[" + index + "]", itm);
                       }
                   })
               }

               formData.append("end_date", this.formData.end_date);
               formData.append("start_date", this.formData.start_date);
               formData.append("subject", this.formData.subject);
               formData.append("subject_en", this.formData.subject_en);
               formData.append("explanation", this.formData.explanation);
               formData.append("explanation_en", this.formData.explanation_en);
               formData.append("link", this.formData.link);
               formData.append("show_on_login", this.formData.show_on_login?1:0);
               if (this.formData.student_program_ids){
                   formData.append("student_program_ids[]", this.formData.student_program_ids.split(/\n/));
               }

               if (this.formData.file && typeof this.formData.file!='undefined'){
                   formData.append("file", this.formData.file);
               }
               formData.append("status", this.formData.status);
               if (isValid) {
                   this.formLoading = true;
                   AnnouncementsService.store(formData)
                       .then(response => {
                           this.$toast.success(this.$t('api.' + response.data.message));
                           this.$emit('createFormSuccess', true);
                       })
                       .catch(e => {
                           this.showErrors(e, this.$refs.formModalValidate);
                       })
                       .finally(() => {
                           this.formLoading = false
                       })

               }

           }

        }
    }
}
</script>
